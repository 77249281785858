<template>
  <div>
    <v-text-field
      v-model="form.email"
      :error-messages="emailErrors"
      :label="$t('common|email')"
      required
      outlined
      @input="$v.form.email.$touch()"
      @blur="$v.form.email.$touch()"
    />
    <v-text-field
      v-model="form.password"
      :error-messages="passwordErrors"
      :label="$t('common|password')"
      type="password"
      required
      outlined
      @input="$v.form.password.$touch()"
      @blur="$v.form.password.$touch()"
    />
    <!-- <v-text-field
      v-model="form.company_name"
      :error-messages="companyNameErrors"
      label="Company Name"
      required
      outlined
      @input="$v.form.company_name.$touch()"
      @blur="$v.form.company_name.$touch()"
    /> -->
    <!-- <v-text-field
      v-model="form.company_registration"
      :error-messages="companyRegistrationErrors"
      label="Company Registration Place"
      required
      outlined
      dense
      @input="$v.form.company_registration.$touch()"
      @blur="$v.form.company_registration.$touch()"
    />
    <v-text-field
      v-model="form.company_type"
      :error-messages="companytTypeErrors"
      label="Company Type"
      required
      outlined
      dense
      @input="$v.form.company_type.$touch()"
      @blur="$v.form.company_type.$touch()"
    />
    <v-text-field
      v-model="form.address"
      :error-messages="addressErrors"
      label="Address"
      required
      outlined
      dense
      @input="$v.form.address.$touch()"
      @blur="$v.form.address.$touch()"
    />
    <v-text-field
      v-model="form.shared_capital"
      :error-messages="sharedCapitalErrors"
      label="Shared Capital"
      type="number"
      required
      outlined
      dense
      @input="$v.form.shared_capital.$touch()"
      @blur="$v.form.shared_capital.$touch()"
    />
    <v-text-field
      v-model="form.rcs_number"
      :error-messages="rcsErrors"
      label="RCS number"
      required
      outlined
      dense
      @input="$v.form.rcs_number.$touch()"
      @blur="$v.form.rcs_number.$touch()"
    />
    <v-text-field
      v-model="form.legal_representative"
      :error-messages="legalRepresentativeErrors"
      label="Legal Representative"
      required
      outlined
      dense
      @input="$v.form.legal_representative.$touch()"
      @blur="$v.form.legal_representative.$touch()"
    /> -->
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email, minLength, helpers } from 'vuelidate/lib/validators'
const alphaNum = helpers.regex('alphaNumAndDot', /^[a-z\d.!#$%&()*+,./=@[\]^_|~]*$/i)

export default {
  mixins: [validationMixin],
  props: {
    formData: {
      type: Object,
      default: () => ({
        accountType: 'company',
        email: null,
        password: null
      })
    }
  },
  data () {
    const form = this.formData
    return {
      form
    }
  },
  validations: {
    form: {
      email: { required, email, alphaNum },
      password: { required, minLength: minLength(7), alphaNum }
    }
  },
  computed: {
    emailErrors () {
      const errors = []
      if (!this.$v.form.email.$dirty) return errors
      !this.$v.form.email.email && errors.push(this.$t('warning|valid_email'))
      !this.$v.form.email.required && errors.push(this.$t('warning|email_required'))
      !this.$v.form.email.alphaNum && errors.push('Your email-address contains forbidden special characters!')
      return errors
    },
    passwordErrors () {
      const errors = []
      if (!this.$v.form.password.$dirty) return errors
      !this.$v.form.password.required && errors.push(this.$t('warning|password_required'))
      !this.$v.form.password.minLength && errors.push(this.$t('warning|password_type'))
      !this.$v.form.password.alphaNum && errors.push(`Your password contains forbidden special characters: { } " - ? : ' < > ${'`'} `)

      return errors
    }
  },
  watch: {
    form: {
      handler (value) {
        if (value.email && value.email.length && value.password && value.password.length) {
          this.$emit('enable-submit-btn')
        } else {
          this.$emit('disable-submit-btn')
        }
      },
      deep: true
    }
  },
  methods: {
    reset () {
      this.form = this.formData
      this.$nextTick(() => { this.$v.form.$reset() })
    },
    validate () {
      this.$v.form.$touch()
      if (this.$v.form.$error) return false
      return true
    },
    getData () {
      return this.form
    }
  }
}
</script>
