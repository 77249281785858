<template>
  <v-container
    fluid
    fill-height
    class="d-block"
  >
    <template v-if="client">
      <template v-if="client.clientType && client.clientType === 'individual'">
        <IndividualRegisterForm ref="regForm" />
        <IndividualClientForm
          :form-data="client.clientData"
          :view="false"
          :has-sharable-link="false"
          :is-external="true"
          @submit="onSubmit"
        />
      </template>
      <template v-if="client.clientType && client.clientType === 'company'">
        <CompanyRegisterForm ref="regForm" />
        <CompanyClientForm
          :form-data="client.clientData"
          :view="false"
          :has-sharable-link="false"
          :is-external="true"
          @submit="onSubmit"
        />
      </template>
    </template>
    <template v-if="!client && !loading">
      <h1>Something is wrong with the link</h1>
    </template>
    <v-dialog
      v-model="successDialog"
      max-width="500"
    >
      <v-card>
        <v-card-text>
          <v-alert
            type="success"
          >
            Successfully registered now you can Log in
          </v-alert>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="$router.push('/login')"
          >
            Go to Login
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import IndividualRegisterForm from '@/components/forms/IndividualRegisterForm.vue'
import CompanyRegisterForm from '@/components/forms/CompanyRegisterForm.vue'
import IndividualClientForm from '@/components/client/IndividualClientForm.vue'
import CompanyClientForm from '@/components/client/CompanyClientForm.vue'

export default {
  components: {
    IndividualRegisterForm,
    CompanyRegisterForm,
    IndividualClientForm,
    CompanyClientForm
  },
  data () {
    return {
      loading: true,
      client: null,
      successDialog: false
    }
  },
  async created () {
    const token = this.$route.params._id
    const clientId = this.$route.query._id
    try {
      const { data } = await this.$axios.get(`/getClientFromExternal/${clientId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      this.client = data.data
    } catch (e) {
      console.error(e, 'error in getData')
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions({
      setToken: 'auth/setToken'
    }),
    async onSubmit (formData) {
      if (!this.$refs.regForm.validate()) return
      const regFormData = this.$refs.regForm.getData()
      const clientId = this.$route.query._id
      try {
        const { data: accountData } = await this.$axios.post('/createAccount', regFormData)
        console.error(accountData, 'accountData')
        this.setToken(accountData)
        formData.account = accountData.data._id
        const { data: clientData } = await this.$axios.post(`/updateClientFromExternal/${clientId}`, formData)
        this.client = clientData.data
        this.successDialog = true
      } catch (e) {
        console.error(e, 'error in getData')
      }
    }
  }
}
</script>
